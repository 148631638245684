import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import 'common/css/usage/introduction.css'
import { useQuery } from 'react-query';
import { numberWithCommas } from 'common/js/func';
import axios from 'axios';
const Introduction = () => {
    const movePage = useNavigate();

    const pageMoveA = (page) => {
        movePage(page);
    }

    const [operInfo, setOperInfo] = useState({
        memberTotal: '276,166',             // 참여인원
        operationDepositCount: 0,   //
        operationMoney: '1조 1311억',          // 누적금액
        operationStage: '45,652'           // 스테이지 수
    })

    useQuery(['getOperationInfo'], async () => {
        const res = await axios.post(`https://app-api-real.iminfintech.co.kr/api/v1/CommonApi/getOperationInfo`, null);
        if (res.status === 200) {
            setOperInfo(res.data.data);
        }
        return res;
    });

    /**
     * 억단위로 변환
     */
    const formatMoney = (number) => {
        if (number === 0) return '0';

        const units = ['억', '조'];
        let result = '';

        // 1e4 10의4제곱 = 10,000
        // 억 단위로 나누고 나머지 부분을 반올림
        const billions = Math.round(number / 1e8);

        // 조 단위와 억 단위로 나누기
        const trillions = Math.round(billions / 1e4);
        const remainingBillions = billions % 1e4;

        if (trillions > 0) {
            result = `${trillions}${units[1]}`;
        }
        if (remainingBillions > 0) {
            result += ` ${remainingBillions.toLocaleString()}${units[0]}`;
        }

        return result.trim();
    }

    return (
        <>
            <div className="pageInfo">
                <div className="centerBox">
                    <span onClick={() => pageMoveA('/')}>홈</span>
                    <span onClick={() => pageMoveA('/usage/Guide')}>이용 문의</span>
                    <span onClick={() => pageMoveA('/usage/Introduction')}>아임인 소개</span>
                </div>
            </div>
            <section className="subCont introduction" id='introduction'>
                {/* <!-- 컨텐츠 --> */}
                <div className="subTitle">
                    <h4>아임인 소개</h4>
                    <p>우리 같이 목돈 모아볼까요? 소셜 핀테크 아임인</p>
                </div>
                {/* <!-- introduction_01 --> */}
                <div className="introduction_01 introductionCont">
                    <div className="txtBox">
                        <p className="txtTitle">소셜 핀테크, <span>아임인</span>이란?</p>
                        <p className="txtSub">아임인은 사람들이 함께 돈을 모아 매달 정한 순번대로 목돈을 <br />
                        수령하는 온라인 계모임 방식의 소셜 핀테크 서비스입니다.</p>
                    </div>
                    <img src="/images/png/img_device.png" alt="소셜 핀테크, '아임인'이란?"/>
                </div>
                {/* <!-- introduction_02 --> */}
                <div className="introduction_02 introductionCont">
                    <div className="txtBox txtC">
                        <p className="txtTitle">목돈이 필요한 모든 순간, <br /><span>아임인</span>을 이용하세요!</p>
                    </div>
                    <ul>
                        <li>
                            <img src="/images/png/ico_f_coin.png" alt="함께 모아 정한 순번대로 목돈을 받는 스테이지"/>
                            <div>
                                <p>앞 순번 회원</p>
                                <p>신용점수 영향없이 <br />대출이 필요한 분들에게 추천</p>
                            </div>
                        </li>
                        <li>
                            <img src="/images/png/ico_m_coin.png" alt="앞 순번은 빠르게 중간 순번은 이자없이, 뒷 순번은 높은 이율"/>
                            <div>
                                <p>중간 순번 회원</p>
                                <p>소비계획에 맞춰 미리 <br />목돈 마련할 분들에게 추천</p>
                            </div>
                        </li>
                        <li>
                            <img src="/images/png/ico_b_coin.png" alt="마음 놓고 이용 할 수 있는 이유! 원금 98% 보장, 안심서비스"/>
                            <div>
                                <p>뒷 순번 회원</p>
                                <p>꾸준하고 안전한 투자 방법을 <br />찾으시는 분들에게 추천</p>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <!-- introduction_03 --> */}
                <div className="introduction_03">
                    <div className="txtBox txtC">
                        <p className="txtTitle">쉽고 간편하게 <br />목돈을 만드는<span> 스테이지</span></p>
                    </div>
                    <ul>
                        <li>
                            <div>
                                <img src="/images/png/banner_04.png" alt="스테이지 참여&개설" />
                            </div>
                            <p>스테이지 참여&개설</p>
                            <p>원하는 조건의 스테이지 <br />참여 or 개설</p>
                        </li>
                        <li>
                            <div>
                                <img src="/images/png/banner_05.png" alt="스테이지 시작" />
                            </div>
                            <p>스테이지 시작</p>
                            <p>모든 순번이 모이면 <br />오전 10시에 시작</p>
                        </li>
                        <li>
                            <div>
                                <img src="/images/png/banner_06.png" alt="매월 정해진 날 입급" />
                            </div>
                            <p>매월 정해진 날 입급</p>
                            <p>매월 정해진 날짜에 <br />적금처럼 입금</p>
                        </li>
                        <li>
                            <div>
                                <img src="/images/png/banner_07.png" alt="내 순번에 목돈 수령" />
                            </div>
                            <p>내 순번에 목돈 수령</p>
                            <p>선택한 순번의 차례가 <br />돌아오면 목돈 수령</p>
                        </li>
                        <li>
                            <div>
                                <img src="/images/png/banner_08.png" alt="스테이지 종료" />
                            </div>
                            <p>스테이지 종료</p>
                            <p>모든 순번이 목돈을 받으면 <br />스테이지 종료</p>
                        </li>
                    </ul>
                </div>
                {/* <!-- introduction_04 --> */}
                <div className="introduction_04">
                    <div className="txtBox txtC">
                        <p className="txtTitle">이미 수많은 사람들이 <br /><span>아임인</span>과 함께 합니다.</p>
                    </div>
                    <ul>
                        <li>
                            <div><img src="/images/png/introduction_01.png" alt="총 스테이지 누적 운영 금액" /></div>
                            <p>총 스테이지 누적 운영 금액</p>
                            {/* <p><span>1조 1,311</span>억</p> */}
                            <p>{formatMoney(operInfo.operationMoney)}</p>
                        </li>
                        <li>
                            <div><img src="/images/png/introduction_02.png" alt="총 스테이지 수" /></div>
                            <p>총 스테이지 수</p>
                            {/* <p><span>45,652</span>개</p> */}
                            <p><span>{numberWithCommas(operInfo.operationStage)}</span>개</p>
                        </li>
                        <li>
                            <div><img src="/images/png/introduction_03.png" alt="총 참여인원" /></div>
                            <p>총 참여인원</p>
                            {/* <p><span>276,166</span>명</p> */}
                            <p><span>{numberWithCommas(operInfo.memberTotal)}</span>명</p>
                        </li>
                    </ul>
                </div>
                {/* <!-- introduction_05 --> */}
                <div className="introduction_05">
                    <div className="txtBox">
                        <p className="txtTitle">목돈이 필요한 이유는 달라도, 함께해요!</p>
                    </div>
                    <ul>
                        <li>
                            <img src="/images/png/img_h01.png" alt="직장인 A씨" />
                            <p>직장인 A씨 (28세)</p>
                            <p>곧 결혼을 앞두고 돈이 필요해서 <br />계획적인 대출이 필요했는데 아임인으로 유용하게 활용했어요.</p>
                        </li>
                        <li>
                            <img src="/images/png/img_h02.png" alt="직장인 B씨" />
                            <p>직장인 B씨 (32세)</p>
                            <p>은행보다 더 나은 저축방법을 찾다가 <br />아임인을 알게됐어요! 목돈 만들기에 꼭 필요해요 &#128151;</p>
                        </li>
                        <li>
                            <img src="/images/png/img_h03.png" alt="직장인 C씨" />
                            <p>자영업 C씨 (47세)</p>
                            <p>대출은 이미 있고, 신용점수도 <br />걱정되는 저같은 자영업자들에겐 <br />아임인을 추천합니다!</p>
                        </li>
                        <li>
                            <img src="/images/png/img_h04.png" alt="직장인 D씨" />
                            <p>직장인 D씨 (41세)</p>
                            <p>시드머니를 모을 수 있는 안전한 <br /> 재테크를 하고 싶어서 아임인을 <br />선택했어요!</p>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Introduction;
